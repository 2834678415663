.userlistpage .main_section .topheader ul {
  display: flex;
  align-items: center;
}
.userlistpage .main_section .topheader ul li {
  margin-right: 5px;
}
.userlistpage .main_section .topheader h5 {
  margin: auto;
}
.userlistpage .main_section .topheader .fa-arrow-circle-o-right {
  color: gray;
}

.userlistpage .main_section .top_section {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.userlistpage .main_section .top_section ul {
  display: flex;
  align-items: center;
}
.userlistpage .main_section .top_section ul li {
  margin-right: 10px;
}
.userlistpage .wrap {
  width: 30%;
}
.userlistpage .main_section .top_section .btn-warning {
  border-radius: 20px;
  font-size: 13px;
  height: 35px;

  margin-right: 10px;
}
.userlistpage .search {
  width: 600px;
  height: 30px;
  position: relative;
  display: flex;
}

.userlistpage .searchTerm {
  width: 100%;
  border: 2px solid #00b4cc;
  border-right: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
  height: 100%;
  font-size: 12px;
}

.userlistpage .searchTerm:focus {
  color: black;
}

.userlistpage .searchButton {
  width: 40px;
  height: 30px;
  border: 1px solid #00b4cc;
  background: #00b4cc;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 15px;
}

.userlistpage .main_section .table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.userlistpage .fa-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
}

.userlistpage .table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f8f8f8;
}
.userlistpage .main_section .table .tablebody .userbg {
  height: 50px;
  width: 50px;
  border: 1px solid rgb(167, 167, 167);
  border-radius: 50px;
}
.userlistpage .main_section .table .tablebody .fa-user {
  position: relative;
  left: 13px;
  top: 12px;
}
.userlistpage .main_section .table .tablebody .image {
  height: 50px;
  width: 50px;
  border: 1px solid rgb(167, 167, 167);
  border-radius: 50px;
}
.userlistpage .main_section .table .tablebody td {
  font-size: 12px;
}

.userlistpage .main_section .table .tablehead th {
  text-align: start;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  color: white;
  background-color: #2c2e3e;
}
.userlistpage .main_section .table .tablebody th {
  line-height: 20px;
}

.userlistpage .main_section .table .tablebody .btn {
  width: 40px;
  height: 30px;
  border-radius: 40px;
  font-size: 10px;
  text-align: center;

  margin: 0px 5px;
}

.userlistpage .loadmoredatasection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
}
.userlistpage .loadmoredatasection .loadmoredataspinner {
  margin-right: 10px;
}
.userlistpage .nomoredatatext {
  margin-right: 10px;
  font-size: 12px;
}
.userlistpage .loadmoredatasection .btn-warning {
  border-radius: 20px;
  font-size: 13px;

  margin-right: 10px;
}
@media screen and (max-width: 1025px) {
  .userlistpage .main_section .top_section {
    flex-direction: column;
  }
}

/*------------------toggle switch --------------*/
.wrg-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.wrg-toggle-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.wrg-toggle-check, .wrg-toggle-uncheck {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  opacity: 0;
  transition: opacity .25s ease;
}
.wrg-toggle-check {
  left: 8px;
}
.wrg-toggle-uncheck {
  opacity: 1;
  right: 10px;
}

.wrg-toggle-uncheck span, 
.wrg-toggle-check span {
  align-items: center;
  display: flex;
  height: 10px;
  justify-content: center;
  position: relative;
  width: 10px;
}

.wrg-toggle-container{
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  transition: all .2s ease;
}

.wrg-toggle-circle{
  transition: all .5s cubic-bezier(.23,1,.32,1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all .25s ease;
}

