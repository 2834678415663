.dashboardpage .topheader {
  width: 120px;
  height: 50px;

  display: flex;
  align-items: center;
}
.dashboardpage .fa-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
}
.dashboardpage .topheader h5 {
  margin: auto;
}
.dashboardpage .topheader .fa-arrow-circle-o-right {
  color: gray;
}

.dashboardpage .first_section .box {
  margin-top: 30px;
  padding: 20px;
  height: 100px;
  border-radius: 10px;
  border: 10px gray;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboardpage .first_section .box i {
  opacity: 0.1;
}

.dashboardpage .first_section .box .box_containt {
  height: 100%;
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboardpage .first_section .box .box_containt .heading {
  font-weight: 700;
  font-size: 30px;
}

.dashboardpage .topheader ul {
  display: flex;
  align-items: center;
}
.dashboardpage .topheader ul li {
  margin-right: 5px;
}
.dashboardpage .topheader h5 {
  margin: auto;
}
.dashboardpage .topheader .fa-arrow-circle-o-right {
  color: gray;
}
.second_section .wrap_chart {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  padding: 10px;
  margin-top: 20px;
}
.third_section .wrap_chart {
  margin-top: 20px;
}
