.editd_person_details_formpage .main_section {
  margin-top: 10px;
}
.editd_person_details_formpage .form-control:valid {
  background-color: white !important;
}
.editd_person_details_formpage .fa-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
}
.editd_person_details_formpage .main_section .topheader ul {
  display: flex;
  align-items: center;
}
.editd_person_details_formpage .main_section .topheader ul li {
  margin-right: 5px;
}
.editd_person_details_formpage .main_section .topheader h5 {
  margin: auto;
}
.editd_person_details_formpage
  .main_section
  .topheader
  .fa-arrow-circle-o-right {
  color: gray;
}

.editd_person_details_formpage .first_section .update_btn {
  font-size: 15px;
  border-radius: 20px;
}

.editd_person_details_formpage .second_section .profileimage {
  border: 5px solid rgb(218, 218, 218);
  border-radius: 10px;
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editd_person_details_formpage .second_section .profileimage .fa-user {
  color: gray;
}

.editd_person_details_formpage .second_section input {
  /* visibility: hidden; */
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.editd_person_details_formpage .second_section .profileimage .netimage {
  height: 200px;
  width: 200px;
  border: 5px solid rgb(218, 218, 218);
  border-radius: 10px;
}
.editd_person_details_formpage .fa-2x {
  position: relative;
  top: auto;
  left: auto;
}

.editd_person_details_formpage .btn_section {
  margin-top: 10px;
  margin-left: 20px;
  width: 200px;
}

.editd_person_details_formpage .second_section label {
  color: black;
  font-size: 12px;
  background-color: white;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 20px;
  padding: 5px 20px;
  cursor: pointer;
  height: 35px;
  line-height: 25px;
  text-align: center;
}
.editd_person_details_formpage .second_section label:hover {
  background-color: rgb(197, 197, 197);
}
.editd_person_details_formpage .second_section .removebutton:hover {
  background-color: rgb(197, 197, 197);
}

.editd_person_details_formpage .second_section .removebutton {
  background-color: white;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 20px;
  font-size: 12px;

  padding: 5px 20px;
}
.editd_person_details_formpage .thrid_section {
  width: 65%;
}

.editd_person_details_formpage .thrid_section h6 {
  font-size: 16px;
  margin-bottom: 20px;
}
.editd_person_details_formpage .thrid_section table tr td {
  font-size: 15px;
  padding: 6px;
  width: 50%;
}
