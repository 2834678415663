.login_page {
  overflow-x: hidden;
}

.login_page .first_section .box {
  background: #11283f;
  height: 100vh;
  margin: 0;
}
.login_page .first_section .box .from_section {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login_page .first_section .box .from_section > i {
  color: white;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 50px;
  border-radius: 100px;
  border: 1px solid grey;
}
.login_page .first_section .box .from_section ul {
  display: flex;
}
.login_page .first_section .box .from_section ul li {
  padding-right: 10px;
}
.login_page .first_section .box .from_section ul li i {
  font-size: 30px;
  color: white;
}
.login_page .first_section .box .from_section .title_section h3 {
  line-height: 10px;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
}
.login_page .first_section .box .from_section form {
  width: 100%;
  padding: 10px 50px;
}
.login_page .first_section .box .from_section form p {
  color: wheat;
}
.login_page .first_section .box .from_section form .invaild {
  display: none;
}
.login_page .first_section .box .from_section form input {
  margin-bottom: 10px;
}
.login_page .first_section .box .from_section form input.form-control-lg {
  background-color: #11283f !important;
  color: white;
  border: 1px solid #51585d;
  padding: 1.5rem 1rem;

  font-size: 16px;
}
.login_page .first_section .box .from_section form .btn {
  width: 100%;
}
.login_page .first_section .box .from_section form .btn i {
  font-size: 15px;
  margin-left: 10px;
}

.login_page .second_section .box {
  height: 100vh;
}

.login_page .second_section .box img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
