.vendorlistpage .main_section .topheader ul {
  display: flex;
  align-items: center;
}
.vendorlistpage .main_section .topheader ul li {
  margin-right: 5px;
}
.vendorlistpage .main_section .topheader h5 {
  margin: auto;
}
.vendorlistpage .main_section .topheader .fa-arrow-circle-o-right {
  color: gray;
}

.vendorlistpage .main_section .top_section {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.vendorlistpage .main_section .top_section ul {
  display: flex;
  align-items: center;
}
.vendorlistpage .main_section .top_section ul li {
  margin-right: 10px;
}
.vendorlistpage .wrap {
  width: 30%;
}
.vendorlistpage .main_section .top_section .btn-warning {
  border-radius: 20px;
  font-size: 13px;
  height: 35px;

  margin-right: 10px;
}
.vendorlistpage .search {
  width: 600px;
  height: 30px;
  position: relative;
  display: flex;
}

.vendorlistpage .searchTerm {
  width: 100%;
  border: 2px solid #00b4cc;
  border-right: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
  height: 100%;
  font-size: 12px;
}

.vendorlistpage .searchTerm:focus {
  color: black;
}

.vendorlistpage .searchButton {
  width: 40px;
  height: 30px;
  border: 1px solid #00b4cc;
  background: #00b4cc;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 15px;
}

.vendorlistpage .main_section .table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.vendorlistpage .fa-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
}

.vendorlistpage .table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f8f8f8;
}
.vendorlistpage .main_section .table .tablebody .userbg {
  height: 50px;
  width: 50px;
  border: 1px solid rgb(167, 167, 167);
  border-radius: 50px;
}
.vendorlistpage .main_section .table .tablebody .fa-user {
  position: relative;
  left: 13px;
  top: 12px;
}
.vendorlistpage .main_section .table .tablebody .image {
  height: 50px;
  width: 50px;
  border: 1px solid rgb(167, 167, 167);
  border-radius: 50px;
}
.vendorlistpage .main_section .table .tablebody td {
  font-size: 12px;
}

.vendorlistpage .main_section .table .tablehead th {
  text-align: start;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  color: white;
  background-color: #2c2e3e;
}
.vendorlistpage .main_section .table .tablebody th {
  line-height: 20px;
}

.vendorlistpage .main_section .table .tablebody .btn {
  width: 40px;
  height: 30px;
  border-radius: 40px;
  font-size: 10px;
  text-align: center;

  margin: 0px 5px;
}

.vendorlistpage .loadmoredatasection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
}
.vendorlistpage .loadmoredatasection .loadmoredataspinner {
  margin-right: 10px;
}
.vendorlistpage .nomoredatatext {
  margin-right: 10px;
  font-size: 12px;
}
.vendorlistpage .loadmoredatasection .btn-warning {
  border-radius: 20px;
  font-size: 13px;

  margin-right: 10px;
}
@media screen and (max-width: 1025px) {
  .vendorlistpage .main_section .top_section {
    flex-direction: column;
  }
}
