* {
  list-style: none;
  box-sizing: border-box;
}

body {
  /* background: #e1ecf2; */
  background: white;
}

.wrapper {
  margin: 0px;
}
.wrapper .sidebar hr {
  background-color: #424953;
}
.wrapper .sidebar .withsubcat {
  padding-bottom: 100px;
}

.wrapper .top_navbar {
  width: calc(100% - 20px);
  height: 60px;
  display: flex;
  position: fixed;
  z-index: 1;
  top: 0px;
}

.wrapper .top_navbar .hamburger {
  width: 70px;
  height: 100%;
  background: #11283f;
  padding: 15px 17px;
  /* border-top-left-radius: 20px; */
  cursor: pointer;
}

.wrapper .top_navbar .hamburger div {
  width: 35px;
  height: 4px;
  background: #adbbc3;
  margin: 5px 0;
  border-radius: 5px;
}

.wrapper .top_navbar .top_menu {
  width: calc(100% - 70px);
  height: 100%;
  background: #fff;
  /* border-top-right-radius: 20px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.wrapper .top_navbar .top_menu .logo {
  color: #2e4ead;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3px;
}

.wrapper .top_navbar .top_menu ul {
  display: flex;
  margin-top: auto;
}

.wrapper .top_navbar .top_menu ul li {
  display: block;
  margin: 0 10px;
  width: 30px;
  height: 30px;
  line-height: 25px;
  text-align: center;
  border: 1px solid #2e4ead;
  border-radius: 50%;
  color: #2e4ead;
}
.wrapper .top_navbar .top_menu ul li .btn {
  color: #11283f;
}
.wrapper .top_navbar .top_menu ul li i {
  font-size: 12px;
}

.wrapper .top_navbar .top_menu ul li:hover {
  background: #4360b5;
  color: #fff;
}

.wrapper .top_navbar .top_menu ul li:hover i {
  color: #fff;
}
.top_menu ul li button {
  font-size: 14px;
}

.wrapper .sidebar {
  position: fixed;
  top: 60px;
  left: 0px;
  overflow-y: scroll;

  z-index: 1;
  background: #11283f;
  width: 200px;
  height: 100%;
  /* height: calc(100% - 80px); */
  /* border-bottom-left-radius: 20px; */
  transition: all 0.3s ease;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #8a9bb2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(54, 56, 58);
}

.wrapper .sidebar .noSubCat ul li {
  display: block;
  padding: 15px;

  position: relative;
  margin-bottom: 1px;
  color: #adbbc3;
  white-space: nowrap;
}
.wrapper .sidebar .noSubCat {
  margin-bottom: 0px;
}
.wrapper .sidebar .withsubcat ul a {
  color: #adbbc3;
  text-decoration: none;
}
.wrapper .sidebar .withsubcat .maincat ul li {
  display: block;
  padding: 15px;

  position: relative;
  margin-bottom: 1px;
  color: #adbbc3;
  white-space: nowrap;
  cursor: pointer;
}
.wrapper .sidebar .withsubcat .subcat ul li {
  display: block;

  padding: 10px;

  position: relative;
  margin-bottom: 1px;
  color: #adbbc3;
  white-space: nowrap;
  left: 20px;
  cursor: pointer;
  text-decoration: none;
}

.wrapper .sidebar ul li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: #92a6e2;
  display: none;
}

.wrapper .sidebar ul li span.icon {
  margin-right: 10px;
  display: inline-block;
}

.wrapper .sidebar ul li span.title {
  font-size: 12px;
  display: inline-block;
}
.wrapper .sidebar ul li span.subtitle {
  font-size: 12px;
}

.wrapper .sidebar ul li:hover,
.wrapper .sidebar ul li.active {
  background: #173757;
  color: #fff;
}
.wrapper .sidebar ul .active {
  background: #173757;
  color: #fff;
}

.wrapper .sidebar ul li:hover:before,
.wrapper .sidebar ul li.active:before {
  display: block;
}

.wrapper .main_container {
  width: (100% - 200px);
  margin-top: 70px;
  margin-left: 200px;
  padding: 15px;
  transition: all 0.3s ease;
}

.wrapper .main_container .item {
  background: #fff;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 14px;
  line-height: 22px;
}
.wrapper.collap {
  overflow-y: auto;
}
.wrapper.collap .sidebar {
  width: 70px;
  overflow-y: visible;
}
/* .wrapper.collap .sidebar .ulwrapper {
  width: 70px;
} */

.wrapper.collap .sidebar .noSubCat ul li {
  text-align: center;
}
.wrapper.collap .sidebar .withsubcat ul li {
  text-align: center;
}
.wrapper.collap .sidebar .noSubCat ul li span.title {
  display: none;
}
.wrapper.collap .sidebar .withsubcat ul li span.title {
  display: none;
}

.wrapper.collap .sidebar .noSubCat ul li:hover span.title {
  display: inline-block;
  position: relative;
  left: 30px;
  padding: 10px 20px;
  background-color: #173757;
}
.wrapper.collap .sidebar .withsubcat ul li:hover span.title {
  display: inline-block;
  position: relative;
  left: 30px;
  padding: 10px 20px;
  background-color: #173757;
}
/* .wrapper.collap .sidebar .withsubcat ul li a:hover span.title {
  display: inline-block;
  position: relative;
  left: 30px;
  padding: 10px 20px;
  background-color: #173757;
} */
.wrapper.collap .sidebar .withsubcat .subcat {
  display: inline-block;
  padding: 20px;
  position: relative;
  top: -85px;
  left: 70px;
  background-color: #11283f;
}
.wrapper.collap .sidebar .withsubcat .subcat ul li {
  left: 0px;
  text-align: start;
}

.wrapper.collap .sidebar .noSubCat ul li span.title:hover {
  background-color: #173757;
}

.wrapper.collap .sidebar .noSubCat ul li span.icon {
  margin: 0;
}

.wrapper.collap .main_container {
  width: (100% - 70px);
  margin-left: 70px;
}

/* .wrapper .sidebar .subcat ul li span {
  padding-left: 30px;

  position: relative;
  margin-bottom: 1px;
  color: #adbbc3;
  white-space: nowrap;
} */
.wrapper .sidebar .withsubcat .subcat.collap {
  display: none;
}

/* .wrapper.collap .sidebar ul li a:hover .subcat ul li span {
  position: relative;
  left: 20px;
  background-color: #173757;
  padding: 5px 5px;
} */
